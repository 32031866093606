<template>
  <LinkRailMenuItem
    :with-chevron="isStoreSelected"
  >
    <template #default="{ opened, toggle }">
      <button
        v-if="isStoreSelected"
        ref="$activator"
        class="category-button link-rail-item store-dropdown-title"
        :aria-expanded="opened"
        @keyup.enter="toggle"
        @click="handleActivatorClick"
        @mouseenter="fireAnalytics"
      >
        <div
          v-if="opened"
          class="store-dropdown-title-extension"
        />
        Shop Your Store
      </button>
      <button
        v-else
        ref="$activator"
        class="category-button link-rail-item store-dropdown-title"
        :aria-expanded="opened"
        @click="handleActivatorClick(true)"
        @keyup.enter="toggle"
      >
        <div
          v-if="opened"
          class="store-dropdown-title-extension"
        />
        Shop Your Store
      </button>
    </template>

    <template
      v-if="isStoreSelected"
      #content="{ opened }"
    >
      <StoreInfo
        v-if="opened"
        :is-opened="opened"
      >
        <template #store-name>
          <span
            v-if="isStoreSelected"
            class="medium"
            :class="{
              hidden: !isStoreSelected && isMobile,
              'fixed-height': isMobile,
            }"
          >
            {{ storeInfoName }}
          </span>
        </template>
      </StoreInfo>
    </template>
  </LinkRailMenuItem>
</template>

<script setup>
/* istanbul ignore file */

import { computed, defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { GET_STORE_NAME, IS_STORE_SELECTED } from '../../types/getters';
import LinkRailMenuItem from './LinkRailMenuItem.common';
import { fireLinkTag } from '../../features/analytics/tagManager';
import { useMobileNav } from '../../composables/useMobileNav';
import useDevice from '../../composables/dumb/useDevice';

const StoreInfo = defineAsyncComponent(() => import(/* webpackChunkName: "StoreInfo" */'../header-responsive/StoreInfo.common'));

const store = useStore();
const { isMenuOpened } = useMobileNav();
const $activator = ref(null);
const { isMobile } = useDevice();

// To test No Store Selected state remove one logical not operator
const isStoreSelected = computed(() => store.getters[`navPreferredStore/${IS_STORE_SELECTED}`]);
const storeInfoName = computed(() => store.getters[`navPreferredStore/${GET_STORE_NAME}`]);

const openStoreSlideout = () => store.dispatch('navPreferredStore/toggleLocatorSlideout');
const fireAnalytics = () => {
  const payload = {
    event_name: 'store header change',
    link_name: 'store header change hover',
  };
  fireLinkTag(payload);
};

function handleActivatorClick(openSlideout) {
  if (isMenuOpened.value) {
    setTimeout(() => {
      $activator.value.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
    });
  } else if (openSlideout) {
    openStoreSlideout();
  }
}
</script>

<style lang="scss" brand="mcom">
.category-button {
  cursor: pointer;
}

.store-dropdown-title {
  position: relative;
  &-extension {
    position: absolute;
    top: 10px;
    right: 0;
    width: 100px;
    height: 40px;
  }
}
</style>
