import { fireLinkTag } from './tagManager';
import { getSiteMode as _getSiteMode } from '../../utils/getSiteMode';

/**
 * New `cm_sp` format
 * Note: for the empty value data elements use na as a default value.
 * e.g. top-nav_21683_WOMEN_Clothing_Dresses_na_na
 */
function _getNewCmspFormat(data) {
  return Object.keys(data).reduce((acc, key) => {
    if (key.startsWith('subCategory')) {
      acc.push(`${data[key]?.length ? data[key] : 'na'}`);
    } else {
      acc.push(data[key]);
    }
    return acc;
  }, []).join('_');
}

function _getTrackingParams(tracking, {
  _tagData,
  isBcomDataValidForNewCmspFormat,
  productBrowseCategoryId,
  context,
}) {
  return Object.entries(tracking || {}).reduce((acc, cur) => {
    const [key, value] = cur;
    acc[key] = typeof value === 'function' ? value(context) : value;

    if (key === 'cm_sp') {
      // need to clean up cm_cp value
      let validCmsp = decodeURIComponent(acc[key]);

      // BCOM new format
      if (isBcomDataValidForNewCmspFormat) {
        validCmsp = _getNewCmspFormat({
          nav_type: 'top-nav',
          productBrowseCategoryId,
          topCategoryName: _tagData.topCategoryName,
          subCategory1Name: _tagData.subCategory1Name,
          subCategory2Name: _tagData.subCategory2Name,
          subCategory3Name: _tagData.subCategory3Name,
          subCategory4Name: _tagData.subCategory4Name,
        });
      }

      acc[key] = validCmsp;
    }

    return acc;
  }, {});
}

export function tagNavLinkClick(isMcom, tagData) {
  const _tagData = tagData;

  // clean up any extra spaces
  Object.keys(_tagData)
    .forEach((k) => {
      if (typeof _tagData[k] === 'string') _tagData[k] = _tagData[k].trim();
    });

  const {
    // dual brand
    analyticsCategoryId = null,
    categoryId,
    context,
    topCategoryName = '',
    subCategory1Name = '',
    subCategory2Name = '',
    subCategory3Name = '',
    subCategory4Name = '',
    tracking,
    // mcom only
    clickableElement = '',
    col,
    pos,
  } = _tagData;

  const productBrowseCategoryId = analyticsCategoryId || categoryId;

  // one of these keys must have value
  const isBcomDataValidForNewCmspFormat = !isMcom
    && (topCategoryName?.length
      || subCategory1Name?.length
      || subCategory2Name?.length
      || subCategory3Name?.length
      || subCategory4Name?.length
    );

  const utagData = {
    event_name: 'nav link click',
    ...(isBcomDataValidForNewCmspFormat ? {
      product_browse_category_id: productBrowseCategoryId,
      nav_type: 'top-nav',
      top_category_name: topCategoryName,
      sub_category1_name: subCategory1Name,
      sub_category2_name: subCategory2Name,
      sub_category3_name: subCategory3Name,
      sub_category4_name: subCategory4Name,
    } : {
      category_id: productBrowseCategoryId,
      top_nav_category_name: topCategoryName,
      col,
      pos,
      primary_component: 'glbhdrnav',
      clickable_element: clickableElement,
      mode: _getSiteMode(context),
    }),
    ..._getTrackingParams(tracking, {
      _tagData,
      isBcomDataValidForNewCmspFormat,
      productBrowseCategoryId,
      context,
    }),
  };

  fireLinkTag(utagData);
}

export default {
  tagNavLinkClick,
};
