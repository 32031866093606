module.exports = [
  {
    description: 'for desktop menu redesign',
    control: '2151-20',
    treatment: '2151-21',
    ks: 'enhancedDesktopNavEnabled',
    devices: ['Desktop'],
    invalidationPrefetch: false, // activate when exp goes live
  },
  {
    description: 'price simplification for best sellers section',
    control: '2054-20',
    treatment: '2054-21',
    ks: 'priceSimplificationEnabled',
    devices: ['Desktop', 'Mobile'],
  },
  {
    description: 'account drawer',
    control: '2132-20',
    treatment: '2132-21',
    ks: 'accountDropdownEnabled',
    devices: ['Desktop', 'Mobile'],
    invalidationPrefetch: true,
  },
];
