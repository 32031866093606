<template>
  <Link
    class="grid-x align-center undecorated-link bag-icon"
    title="Shopping bag"
    :href="Links.bag.url"
    :tracking="Links.bag.tracking"
    :class="[iconClass, {'bag-filled': !isEmpty}]"
    :style="iconStyle"
    :aria-label="ariaLabel"
    @mouseenter="onHover"
  >
    <div
      v-if="!isEmpty"
      class="grid-x align-center full-height"
      :class="isMcom ? 'align-middle' : 'align-bottom'"
    >
      <span
        class="counter small"
      >{{ count }}</span>
    </div>
  </Link>
</template>

<script setup>
import {
  ref, computed, onMounted, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { Links } from 'config';

import Link from './Link';

import { useBag } from '../../composables/dumb/useBag';
import { useBackboneEvents } from '../../composables/dumb/useBackboneEvents';
import events from '../../constants/mcom/events';
import { SET_COUNT, INCREASE_COUNT } from '../../types/mutations';
import { GET_IS_EMPTY, GET_FORMATTED_COUNT, GET_IS_MORE_THAN_ONE } from '../../types/getters';
import getAssetImage from '../../utils/getAssetImage';
import { tagBagHover } from '../../features/analytics/bagTags';

const store = useStore();
const { isMcom } = store.state.envProps;

const NUMBERED_BAG_SELECTOR = isMcom ? 'bag-large-filled-red' : 'numbered-bag';
const UN_NUMBERED_BAG_SELECTOR = isMcom ? 'bag-large-star' : 'bag-logo-brown';

const isEmpty = computed(() => store.getters[`navQuickBag/${GET_IS_EMPTY}`]);
const count = computed(() => store.getters[`navQuickBag/${GET_FORMATTED_COUNT}`]);
const isMoreThanOne = computed(() => store.getters[`navQuickBag/${GET_IS_MORE_THAN_ONE}`]);
const iconClass = computed(() => (isEmpty.value ? UN_NUMBERED_BAG_SELECTOR : NUMBERED_BAG_SELECTOR));
const ariaLabel = computed(() => `${count.value} item${isMoreThanOne.value ? 's' : ''} in bag`);
const iconStyle = computed(() => (!isEmpty.value && !isMcom ? { backgroundImage: `url('${getAssetImage('numberedBag.svg')}')` } : {}));
const isHovered = ref(false);

function setBagCount(bagCount) {
  store.commit(`navQuickBag/${SET_COUNT}`, bagCount);
}

function increaseBagCount(bagCount) {
  store.commit(`navQuickBag/${INCREASE_COUNT}`, bagCount);
}

function onHover() {
  if (!isHovered.value) {
    isHovered.value = true;
    tagBagHover();
  }
}

onMounted(() => {
  const { getBagCountCookie } = useBag();
  const { listenTo } = useBackboneEvents();
  const bagCount = getBagCountCookie();

  setBagCount(bagCount);
  listenTo(events.bag.setBagCount, setBagCount);
  listenTo(events.bag.increaseBagCount, increaseBagCount);
});

onUnmounted(() => {
  const { stopListening } = useBackboneEvents();

  stopListening(events.bag.setBagCount, setBagCount);
  stopListening(events.bag.increaseBagCount, increaseBagCount);
});
</script>

<style lang="scss" scoped>
.bag-icon {
  transform: translate3d(0, 0, 0); // HFR-1045: hack for Safari to render correctly instead of pixelated background image
}
</style>

<style lang="scss" brand="mcom" scoped>
.counter {
  display: block;
  margin-top: 5px;
  font-weight: 700;
  color: $white;
}
</style>

<style lang="scss" brand="bcom" scoped>
@import "../../scss/icons.bcom.scss";

.bag-icon {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  display: block;
  background-repeat: no-repeat;

  @include breakpoint(large up) {
    width: 28px;
    height: 28px;
    background-size: 28px 28px;
  }
}

.counter {
  display: block;
  font-weight: 700;
  color: $secondary-color;

  @include breakpoint(large up) {
    margin-bottom: 2px;
  }
}
</style>
