import { computed } from 'vue';

import useExperiments from './useExperiments';
import useDevice from './dumb/useDevice';

export default function useFeatureEligibility() {
  const experiments = useExperiments();
  const { isMobile } = useDevice();
  const enhancedDesktopNavFeature = computed(() => !isMobile.value && experiments.value?.enhancedDesktopNavEnabled);

  return {
    enhancedDesktopNavFeature,
  };
}
