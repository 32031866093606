<template>
  <Minimal v-if="isMinimal" />

  <template v-else>
    <DealsCards v-if="isDealsEnabled" />
    <Desktop v-if="isDesktop" />
    <Mobile v-else-if="isMobile">
      <Skeleton
        v-if="isKsFlagsLoading"
        :height="'43px'"
      />
      <MediaRail v-else-if="!isPromoHiddenMew && !isDealsEnabled" />
    </Mobile>
  </template>
</template>

<script setup>
/* istanbul ignore file */
import { ref, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import Skeleton from '@atomic-ui/skeleton';

import Desktop from './header-desktop/Desktop.mcom';
import Mobile from './header-mobile/Mobile.common';
import MediaRail from './header-responsive/MediaRail.mcom';
import DealsCards from './header-responsive/DealsCards.mcom';
import useKillswitches from '../composables/useKillswitches';
import { onKsFlagsReady } from '../events';
import addWindowPop from '../utils/addWindowPop';
import useDevice from '../composables/dumb/useDevice';

const props = defineProps({ // eslint-disable-line no-undef
  isMinimal: {
    type: Boolean,
    default: false,
  },
  isPromoHiddenMew: {
    type: Boolean,
    default: false,
  },
  isPermanentSigninTooltip: {
    type: Boolean,
    default: false,
  },
  isDeals: {
    type: Boolean,
    default: false,
  },
});

const Minimal = defineAsyncComponent(() => import(/* webpackChunkName: "Minimal" */ './header-responsive/MinimalHeader.common'));

const store = useStore();
const killswitches = useKillswitches();
const { hostlink } = store.state.envProps;
const { isMobile, isDesktop } = useDevice();
const isDealsEnabled = computed(() => !!killswitches.value.vueDealsEnabled && props.isDeals);
const isKsFlagsLoading = ref(true);

addWindowPop(hostlink);

onKsFlagsReady(() => {
  isKsFlagsLoading.value = false;
});
</script>
