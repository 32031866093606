<template>
  <div
    :title="regionCode"
    class="nav-flag"
    :style="{width, height}"
  >
    <Picture
      :image-url="flagIconUrl"
      :alt="flagIconAlt"
      :using-scene7="false"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Picture from '@atomic-ui/picture';
import { getFlagUrl } from 'config';
import getAssetImage from '../../utils/getAssetImage';

defineProps({
  width: {
    type: String,
    default: '21px',
  },
  height: {
    type: String,
    default: '14px',
  },
});

const store = useStore();

const regionCode = computed(() => store.state.pageData.navigation.context.regionCode);
const flagIconUrl = computed(() => getFlagUrl(regionCode.value, getAssetImage));
const flagIconAlt = computed(() => `${regionCode.value} current selected country`);
</script>

<style scoped lang="scss">
.nav-flag {
  width: 100%;
  height: 100%;
}

.rounded {
  :deep(img) {
    border-radius: 50%;
  }
}

:deep(.picture-container) {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:deep(img) {
  vertical-align: top;
  height: 100%;
}
</style>
