/* eslint-disable no-shadow */
import { normalizeRewardsData, storeLoyaltyStatusForLegacy } from '../../composables/useLoyalty';
import { useLoyaltyPointsFetch } from '../../composables/dumb/useLoyaltyPointsFetch';
import { NAV_KILLSWITCH_STORE } from '../../types/names';

export function state() {
  return {
    rewardsData: null,
  };
}

export const getters = {
  getRewardsData(state) {
    return state.rewardsData || {};
  },
};

export const mutations = {
  setRewardsData(state, data) {
    state.rewardsData = data;
  },
};

export const actions = {
  async fetchRewardsData({ commit, state, rootState }) {
    if (state.rewardsData) {
      return;
    }

    const { isMcom, hostlink } = rootState.envProps;
    const { isSignedIn, guid, uid } = rootState.navUser.data;
    const { killswitches } = rootState[NAV_KILLSWITCH_STORE];

    const { fetch, result } = await useLoyaltyPointsFetch({
      isSignedIn, uid, guid, isMcom, killswitches, hostlink,
    });

    await fetch();

    if (result.value && !result.value.errors) {
      const data = normalizeRewardsData(result.value, isMcom);

      commit('setRewardsData', data);
      storeLoyaltyStatusForLegacy(result.value, isMcom);
    } else {
      storeLoyaltyStatusForLegacy({}, isMcom);
    }
  },
};
