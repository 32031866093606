<template>
  <nav
    ref="navigation"
    class="navigation-rail"
    data-testid="navigation-rail"
    @mouseleave="close"
  >
    <ul
      id="nav-top-menu"
      class="grid-container grid-x align-justify"
    >
      <li
        v-if="experiments.enhancedDesktopNavEnabled"
        class="fob-item hamburger-menu"
        :class="{ 'active': isMenuOpened }"
        @mouseover="closeFlyouts"
      >
        <button
          ref="menuTarget"
          aria-label="Open Shop All Menu"
          aria-haspopup="true"
          aria-controls="mobile-nav"
          :aria-expanded="isMenuOpened"
          data-testid="burger-button"
          class="hamburger-menu-btn grid-x padding-bottom-xs"
          @click.prevent="toggleNav"
        >
          <span
            class="hamburger-menu-icon grid-y"
            :class="{'hamburger-menu-icon--open': isMenuOpened}"
          >
            <span />
            <span />
            <span />
          </span>
          <span
            class="menu-link-heavy menu-label"
            :class="fobClass"
          >Shop All</span>
        </button>
      </li>
      <li
        v-for="(fob, index) in fobs"
        :key="fob.id"
        class="fob-item"
        :class="{ 'active': isActive(fob.text), 'padding-bottom-xs': !experiments.enhancedDesktopNavEnabled, 'menu-opened': isMenuOpened }"
        @mouseover="onMouseOver(fob)"
        @mouseleave="onMouseLeave"
      >
        <Link
          :id="`fob-${fob.text}`"
          :href="fob.url"
          :data-id="fob.id"
          :data-tracking="trackingObjToString(fob.tracking)"
          class="menu-link-heavy"
          :class="fobClass"
          :style="fob.color && { color: fob.color }"
          @click="onFlyoutLinkClick($event, fob, index)"
          @keydown.arrow-left.prevent="onKeydownArrowLeft(navigation)"
          @keydown.arrow-right.prevent="onKeydownArrowRight(navigation)"
        >
          {{ fob.text }}
        </Link>

        <button
          class="chevron-button"
          :class="{ 'active': isActive(fob.text) }"
          :aria-expanded="isActive(fob.text)"
          :aria-controls="fob.text"
          :aria-label="`Open ${fob.text} menu`"
          @keydown.tab="close"
          @keydown.esc.prevent="close"
          @keydown.arrow-up.prevent="close"
          @keydown.enter.prevent="onToggleVisibility(fob)"
          @keydown.space.prevent="onToggleVisibility(fob)"
          @keydown.arrow-down.prevent="onToggleVisibility(fob)"
          @keydown.arrow-left.prevent="onKeydownArrowLeft(navigation)"
          @keydown.arrow-right.prevent="onKeydownArrowRight(navigation)"
        >
          <span class="chevron-small-down" />
        </button>
      </li>
    </ul>
    <Flyouts
      :flyout-category-name="activeFobId"
      @close="closeAndMoveFocusToCurrentFob"
    />
    <div
      v-if="experiments.enhancedDesktopNavEnabled"
      class="wrapper grid-container grid-x align-justify"
      :class="{ active: isMenuOpened }"
      :style="contentWrapStyles"
    >
      <MobileMenu />
    </div>
  </nav>
</template>
<script setup>
/* istanbul ignore file */
import {
  computed, watch, ref, defineAsyncComponent, onMounted,
} from 'vue';
import { useStore } from 'vuex';

import Link from '../common/Link';
import Flyouts from './Flyouts.common';

import { useDesktopNavigation } from '../../composables/accessibility/useDesktopNavigation';
import { useOverlay } from '../../composables/ui/useOverlay';

import { tagNavLinkClick } from '../../features/analytics/navigationTags';
import { useHeaderMenuData } from '../../composables/useHeaderMenuData';
import { trackingObjToString } from '../../utils/restoreStateFromDom';
import useExperiments from '../../composables/useExperiments';
import { useMobileNav } from '../../composables/useMobileNav';

const MobileMenu = defineAsyncComponent(() => import(/* webpackChunkName: "MobileMenu" */ '../header-mobile/MobileMenu.common'));

const navigation = ref(null);

const store = useStore();
const { isMcom } = store.state.envProps;
const overlay = useOverlay('NAVIGATION_OVERLAY');
const {
  activeFobId,
  open,
  close,
  isActive,
  onToggleVisibility,
  onKeydownArrowLeft,
  onKeydownArrowRight,
  moveFocusToCurrentFob,
} = useDesktopNavigation();
const { getFlyouts } = useHeaderMenuData(store);
const experiments = useExperiments();

const { context } = store.state.pageData.navigation;
const {
  menuTarget, isMenuOpened, toggleNav, closeNav,
} = useMobileNav();
const fobs = computed(() => getFlyouts());
const fobClass = computed(() => (fobs.value.length > 12 ? 'fob-small' : ''));
const hoveredFobId = ref();
const headerHeight = ref(0);
const contentWrapStyles = computed(() => ({ height: `calc(100vh - ${headerHeight.value})` }));

function closeAndMoveFocusToCurrentFob() {
  moveFocusToCurrentFob();
  close();
}

function onMouseOver(fob) {
  hoveredFobId.value = fob.id;

  setTimeout(() => {
    if (hoveredFobId.value === fob.id) {
      open(fob);
    }
  }, 200);
}

function onFlyoutLinkClick(event, fob, index) {
  if (isMenuOpened.value) {
    event.preventDefault();
    closeNav();
    onMouseOver(fob);
    return;
  }

  tagNavLinkClick(isMcom, {
    categoryId: fob.id,
    clickableElement: fob.text,
    col: 1,
    pos: index + 1,
    context,
    tracking: fob.tracking,
    topCategoryName: fob.text,
  });
}

function onMouseLeave() {
  hoveredFobId.value = null;
}

function closeFlyouts() {
  onMouseLeave();
  close();
}

function getHeaderHeight() {
  const header = document.getElementById('nav-header-root');
  headerHeight.value = `${header?.offsetHeight || 0}px`;
}

watch(activeFobId, (value) => {
  if (value) {
    overlay.show();
  } else {
    overlay.hide();
  }
});

onMounted(() => {
  getHeaderHeight();
});
</script>

<style lang="scss" scoped>
@import "../../scss/mixins/index.scss";

.wrapper {
  position: relative;
  overflow: hidden;

  &.active {
    z-index: auto;
    overflow: visible;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -100%;
      top: 1px;
      height: 100vh;
      width: 100%;
      background-color: $white;
      z-index: 100;
    }
  }
}

.navigation-rail {
  position: relative;
  border-bottom: 1px solid $gray-4-color;
}

.menu-link-heavy {
  display: block;
  border: none;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.menu-opened {
  opacity: 0.3;
  pointer-events: none;
}

.fob-item {
  position: relative;
  @include padding-top-xs;

  &:hover,
  &:focus,
  &.active {
    &::after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $black;
    }
  }
}

.chevron-button {
  position: absolute;
  bottom: 0;
  height: 16px;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;

  &:focus {
    opacity: 1;
    pointer-events: auto;
  }

  &.active {
    .chevron-small-down {
      transform: rotate(180deg);
    }
  }

  .chevron-small-down {
    position: absolute;
    left: calc(50% - 12px);
  }
}

.hamburger-menu-btn {
  cursor: pointer;
  align-items: center;

  .menu-label {
    margin-left: 12px;
  }
}

.hamburger-menu-icon {
  position: relative;
  width: 16px;
  height: 16px;
  align-items: center;

  span {
    background-color: #000;
    display: block;
    height: 2px;
    width: 15px;
    border-radius: 2px;
    margin-top: 3px;
    transition: all 120ms ease-in;
  }

  span:nth-child(1) {
    margin-top: 1px;
  }

  &--open {
    span:nth-child(1) {
      transform: translateY(5px) rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: translateY(-5px) rotate(-45deg);
    }
  }
}

// prevent links overlapping for small desktop screens
@media only screen and (max-width: 1120px) {
  .fob-small {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped brand="mcom">
@import "../../scss/icons.mcom.scss";

.navigation-rail {
  height: 53px;
}

.chevron-button {
  height: 14px;

  .chevron-small-down {
    @extend .chevron-small-down-black;
    top: -5px;
  }
}

.hamburger-menu-icon {
  top: 1px;
}
</style>

<style lang="scss" scoped brand="bcom">
@import "../../scss/icons.bcom.scss";

.navigation-rail {
  height: 49px;
}

.chevron-button {
  height: 16px;

  .chevron-small-down {
    @extend .chevron-small-down;
    top: -4px;
  }
}

.menu-link-heavy {
  font-size: 12px;
  line-height: 16px;
}

.hamburger-menu-btn {
  .menu-label {
    text-transform: uppercase;
  }
}
</style>
