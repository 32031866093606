import { computed } from 'vue';
import { useStore } from 'vuex';

import { NAV_VIEW_TYPE_STORE } from '../../types/names';
import { GET_IS_MOBILE_VIEW } from '../../types/getters';

export default function useDevice() {
  const store = useStore();
  const isMobile = computed(() => store.getters[`${NAV_VIEW_TYPE_STORE}/${GET_IS_MOBILE_VIEW}`]);
  const isDesktop = computed(() => !isMobile.value);

  return { isMobile, isDesktop };
}
