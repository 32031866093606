module.exports = [
  {
    description: 'for desktop menu redesign',
    control: '2151-20',
    treatment: '2151-21',
    ks: 'enhancedDesktopNavEnabled',
    invalidationPrefetch: false, // activate when exp goes live
    devices: ['Desktop'],
  },
  {
    description: 'account drawer',
    control: '2162-20',
    treatment: '2162-21',
    ks: 'accountDropdownEnabled',
    devices: ['Desktop', 'Mobile'],
    invalidationPrefetch: true,
  },
  {
    description: 'disable media rail',
    control: '2310-20',
    treatment: '2310-21',
    ks: 'disableMediaRail',
    devices: ['Desktop', 'Mobile'],
  },
];
